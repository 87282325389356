<template>
  <b-container fluid>
    <b-row>
      <b-col cols="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Basic Treeview</h4>
          </template>
          <template v-slot:headerAction>
            <a class="text-primary float-right" v-b-toggle.collapse-1 role="button">
              <i class="ri-code-s-slash-line" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-1" class="mb-2">
              <div class="card">
                <kbd class="bg-dark">
                  <!--                  <a href="javascript:void(0)" v-b-tooltip.hover data-copy-target="#basic-treeview" title="Copy" class="text-white float-right" data-copy="true">Copy</a>-->
                  <pre class="text-white" id="basic-treeview"><code>
&#x3C;template&#x3E;
    &#x3C;button class="btn btn-primary" @click.prevent="confirmDelete()"&#x3E;
      Click Here
    &#x3C;/button&#x3E;
    &#x3C;span class="ml-5"&#x3E;&#x3C;b&#x3E;{{ actionValue }}&#x3C;/b&#x3E;&#x3C;/span&#x3E;
    &#x3C;CustomAlert
      modalId=&#x22;iq-delete-modal&#x22; modalRef=&#x22;iq-delete-modal&#x22; @clicked=&#x22;action&#x22;
    /&#x3E;
&#x3C;/template&#x3E;
&#x3C;script&#x3E;
export default {
  name: 'Alert',
  data () {
    return {
      actionValue: false
    }
  },
  methods: {
    confirmDelete () {
      this.$root.$emit('bv::show::modal', 'iq-delete-modal', '#btnShow')
    },
    action (e) {
      this.actionValue = e
    }
  }
}
&#x3C;/script&#x3E;
</code></pre>
                </kbd>
              </div>
            </b-collapse>
            <ul class="p-0">
              <button class="btn btn-primary" @click.prevent="confirmDelete()">Click Here</button><b class="h4 ml-3 text-uppercase">{{ actionValue }}</b>
            </ul>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CustomAlert modalId="iq-delete-modal" modalRef="iq-delete-modal" @clicked="action" />
  </b-container>
</template>

<script>

export default {
  name: 'Alert',
  data () {
    return {
      actionValue: false
    }
  },
  methods: {
    confirmDelete () {
      this.$root.$emit('bv::show::modal', 'iq-delete-modal', '#btnShow')
    },
    action (e) {
      this.actionValue = e
    }
  }
}
</script>
